import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lsystem:
      {
        name: '',
        startwort: '',
        winkel: 0,
        regeln: [],
      },
    ableitungen: [],
    hervorhebungen: [[]],
    vorgaenger: [],
    beispiele: Object.freeze([
      {
        name: 'Kochsche Kurve',
        startwort: 'F',
        winkel: 60,
        regeln:
          [
            { vorgaenger: 'F', nachfolger: 'F+F--F+F' },
          ],
      },
      {
        name: 'Kochsche Schneeflocke',
        startwort: 'F++F++F',
        winkel: 60,
        regeln:
          [
            { vorgaenger: 'F', nachfolger: 'F-F++F-F' },
          ],
      },
      {
        name: 'Drachenkurve',
        startwort: 'FG',
        winkel: 90,
        regeln:
          [
            { vorgaenger: 'G', nachfolger: 'G+HF+' },
            { vorgaenger: 'H', nachfolger: '-FG-H' },
          ],
      },
      {
        name: 'Antenne',
        startwort: '++A',
        winkel: 18,
        regeln:
          [
            { vorgaenger: 'A', nachfolger: 'C[C+++++F]C[-----F]C[C+++++DG]C[-----DG]A' },
            { vorgaenger: 'B', nachfolger: 'BC' },
            { vorgaenger: 'C', nachfolger: 'B' },
            { vorgaenger: 'D', nachfolger: 'C' },
            { vorgaenger: 'F', nachfolger: 'A' },
            { vorgaenger: 'G', nachfolger: 'F' },
          ],
      },
    ]),
    zeichenoptionen:
      {
        streckentyp: 'konstant',
        konstanteStreckenlaenge: true,
        streckenlaenge: 25,
        startwinkel: 0,
        fuellen: false,
      },
    anzeigehilfen:
      {
        richtungspfeile: false,
        drehungen: false,
        vorgaengerUndNachfolger: false,
        positionsspeicherAktionen: false,
      },
    gespeicherteSysteme: [],
  },
  mutations: {
    aendereLsystem(state, lsystem) {
      this.state.lsystem = lsystem;
    },
    aendereAbleitungen(state, ableitungen) {
      this.state.ableitungen = ableitungen;
    },
    ableitungHinzufuegen(state, ableitung) {
      this.state.ableitungen.push(ableitung);
    },
    aendereHervorhebungen(state, hervorhebungen) {
      this.state.hervorhebungen = hervorhebungen;
    },
    hervorhebungenHinzufuegen(state, [schritt, hervorhebungen]) {
      this.state.hervorhebungen[schritt] = hervorhebungen;
    },
    aendereVorgaenger(state, vorgaenger) {
      this.state.vorgaenger = vorgaenger;
    },
    aendereZeichenoptionen(state, zeichenoptionen) {
      this.state.zeichenoptionen = zeichenoptionen;
    },
    aendereAnzeigehilfen(state, anzeigehilfen) {
      this.state.anzeigehilfen = anzeigehilfen;
    },
    speichereSystem(state, lsystem) {
      const regeln = [];
      for (let i = 0; i < lsystem.regeln.length; i += 1) {
        regeln.push({
          vorgaenger: lsystem.regeln[i].vorgaenger,
          nachfolger: lsystem.regeln[i].nachfolger,
        });
      }
      this.state.gespeicherteSysteme.push({
        name: lsystem.name,
        winkel: lsystem.winkel,
        startwort: lsystem.startwort,
        regeln,
        speicherdatum: new Date().toLocaleDateString('de-DE'),
        speicherzeit: new Date().toLocaleTimeString('de-DE'),
      });
    },
    importiereSysteme(state, importierendeSysteme) {
      for (let i = 0; i < importierendeSysteme.length; i += 1) {
        this.state.gespeicherteSysteme.push(importierendeSysteme[i]);
      }
    },
    loeschen() {
      this.state.lsystem = {
        name: '',
        startwort: '',
        winkel: 0,
        regeln: [],
      };
      this.state.ableitungen = [];
      this.state.hervorhebungen = [[]];
      this.vorgaenger = [];
    },
  },
  getters: {
    lsystem: (state) => state.lsystem,
    ableitungen: (state) => state.ableitungen,
    ableitung: (state, index) => state.ableitungen[index],
    beispiele: (state) => state.beispiele,
    hervorhebungen: (state) => state.hervorhebungen,
    vorgaenger: (state) => state.vorgaenger,
    zeichenoptionen: (state) => state.zeichenoptionen,
    anzeigehilfen: (state) => state.anzeigehilfen,
    gespeicherteSysteme: (state) => state.gespeicherteSysteme,
  },
  actions: {
  },
  modules: {
  },
});
