import Vue from 'vue';
import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    regelnKopie(regeln) {
      if (regeln === undefined || regeln.length === 0) return [];
      const regelnKopie = [];
      for (let j = 0; j < regeln.length; j += 1) {
        regelnKopie.push({
          vorgaenger: regeln[j].vorgaenger,
          nachfolger: regeln[j].nachfolger,
        });
      }
      return regelnKopie;
    },
    lsystemKopie(lsystem) {
      if (Object.entries(lsystem).length === 0) return null;
      const neuesLsystem = {
        name: lsystem.name,
        startwort: lsystem.startwort,
        winkel: lsystem.winkel,
        regeln: this.regelnKopie(lsystem.regeln),
      };
      return { ...neuesLsystem };
    },
    letzterUndefinierterIndex(array) {
      let i = array.length - 1;
      for (i; i >= 0; i -= 1) {
        if (array[i] === undefined) return i;
      }
      return -1;
    },
  },
});

new Vue({
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
