<template>
  <v-card>
    <v-tabs
      v-model="tab"
      background-color="primary"
      dark
      show-arrows
    >
      <v-tab
        v-for="item in tabs"
        :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <!-- Standardarstellung  -->
    <v-container v-if="tab===0">
      <v-row no-gutters>
        <v-col>
          <v-card
            v-if="nurNaechstesWort"
            height="600px"
          >
            <v-card-title
              class="text-center justify-center align-center"
            >
              <h1>
                ???
              </h1>
            </v-card-title>
          </v-card>
          <GeneratorZeichnung
            v-else
            :schritt="schritt"
            :wort="ableitungen[schritt]"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          md="12"
          offset="4"
        >
          <GeneratorSteuerung
            v-if="istAbleitbar"
            :schritt="schritt"
            :ableitungen="ableitungen"
            :zeige-schritt="true"
            :nur-naechste-zeichnung="nurNaechsteZeichnung"
            :nur-naechstes-wort="nurNaechstesWort"
            @schrittVor="schrittVor"
            @schrittVorNurZeichnung="schrittVor_Zeichnung"
            @schrittVorNurWort="schrittVor_Wort"
            @schrittZurueck="schrittZurueck"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-card
            v-if="nurNaechsteZeichnung"
            height="600px"
          >
            <v-card-title
              class="text-center justify-center align-center"
            >
              <h1>
                ???
              </h1>
            </v-card-title>
          </v-card>
          <GeneratorWort
            v-else
            :schritt="schritt"
            :wort="ableitungen[schritt]"
          />
        </v-col>
      </v-row>
    </v-container>
    <!-- Darstellung zwei Schritte nebeneinander  -->
    <v-container v-if="tab===1">
      <v-row no-gutters>
        <v-col>
          <GeneratorZeichnung
            v-if="schritt===0"
            :schritt="schritt"
            :wort="ableitungen[schritt]"
          />
          <GeneratorZeichnung
            v-else-if="schritt>0"
            :schritt="schritt-1"
            :wort="ableitungen[schritt-1]"
          />
        </v-col>
        <v-col>
          <v-card
            v-if="nurNaechstesWort"
            height="600px"
          >
            <v-card-title
              class="text-center justify-center align-center"
            >
              <h1>
                ???
              </h1>
            </v-card-title>
          </v-card>
          <GeneratorZeichnung
            v-else-if="this.schritt>0"
            :schritt="schritt"
            :wort="ableitungen[schritt]"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="6"
          v-if="schritt>1"
        >
          <h2 align="center">
            {{ schritt - 1 }}.
          </h2>
          <h4 align="center">
            Ableitung
          </h4>
        </v-col>
        <v-col
          cols="6"
          v-else-if="schritt===0 || schritt===1"
        >
          <h4 align="center">
            Startwort
          </h4>
        </v-col>
        <v-col
          cols="6"
          v-if="schritt>0"
        >
          <h2 align="center">
            {{ schritt }}.
          </h2>
          <h4 align="center">
            Ableitung
          </h4>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          md="10"
          offset="5"
        >
          <GeneratorSteuerung
            v-if="istAbleitbar"
            :schritt="this.schritt"
            :ableitungen="this.ableitungen"
            :nur-naechste-zeichnung="nurNaechsteZeichnung"
            :nur-naechstes-wort="nurNaechstesWort"
            @schrittVor="schrittVor"
            @schrittVorNurZeichnung="schrittVor_Zeichnung"
            @schrittVorNurWort="schrittVor_Wort"
            @schrittZurueck="schrittZurueck"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <GeneratorWort
            v-if="anzahlAbleitungen>-1"
            :schritt="schritt-1"
            :wort="ableitungen[schritt-1]"
          />
        </v-col>
        <v-col>
          <v-card
            v-if="nurNaechsteZeichnung"
            height="600px"
          >
            <v-card-title
              class="text-center justify-center align-center"
            >
              <h1>
                ???
              </h1>
            </v-card-title>
          </v-card>
          <GeneratorWort
            v-else-if="anzahlAbleitungen>0"
            :schritt="schritt"
            :wort="ableitungen[schritt]"
          />
        </v-col>
      </v-row>
    </v-container>
    <!-- Darstellung mit Tabelle  -->
    <v-container v-if="tab===2">
      <v-row no-gutters>
        <v-col cols="5">
          <v-card
            class="mx-auto"
            tile
          >
            <v-list
              flat
            >
              <v-subheader>Ableitungen</v-subheader>
              <v-list-item-group
                v-model="schritt"
                color="primary"
                mandatory
                style="max-height: 900px"
                class="overflow-y-auto"
              >
                <v-list-item
                  v-for="(ableitung, index) in ableitungen"
                  :key="index"
                >
                  <v-list-item-avatar>
                    {{ index }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <GeneratorWort
                      v-if="!(index === schritt && nurNaechsteZeichnung)"
                      :schritt="index"
                      :wort="ableitung"
                    />
                    <span v-else-if="(index === schritt && nurNaechsteZeichnung)">
                      ???
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col
          md="2"
          cols="2"
          align-self="center"
        >
          <GeneratorSteuerungVertikal
            v-if="istAbleitbar"
            :schritt="this.schritt"
            :ableitungen="this.ableitungen"
            :nur-naechste-zeichnung="nurNaechsteZeichnung"
            :nur-naechstes-wort="nurNaechstesWort"
            @schrittVor="schrittVor"
            @schrittVorNurZeichnung="schrittVor_Zeichnung"
            @schrittVorNurWort="schrittVor_Wort"
            @schrittZurueck="schrittZurueck"
          />
        </v-col>
        <v-col cols="5">
          <v-card
            v-if="nurNaechstesWort"
          >
            <v-card-title
              class="text-center justify-center align-center"
            >
              <h1>
                ???
              </h1>
            </v-card-title>
          </v-card>
          <GeneratorZeichnung
            v-else
            :schritt="schritt"
            :wort="ableitungen[schritt]"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import GeneratorZeichnung from '@/components/GeneratorZeichnung.vue';
import GeneratorWort from '@/components/GeneratorWort.vue';
import GeneratorSteuerung from '@/components/GeneratorSteuerung.vue';
import GeneratorSteuerungVertikal from '@/components/GeneratorSteuerungVertikal.vue';

export default {
  name: 'Generator',
  components: {
    GeneratorSteuerungVertikal, GeneratorSteuerung, GeneratorWort, GeneratorZeichnung,
  },
  data() {
    return {
      lsystem: null,
      ableitungen: [],
      schritt: 0,

      nurNaechstesWort: false,
      nurNaechsteZeichnung: false,

      tabs: ['Standard', '2-Nebeneinander', 'Tabelle'],
      tab: 0,
    };
  },
  computed: {
    istAbleitbar() {
      return (this.lsystem !== null
        && this.lsystem.regeln !== null
        && this.lsystem.regeln.length > 0);
    },
    anzahlAbleitungen() {
      return this.ableitungen.length - 1;
    },
    regelHashtabelle() {
      if (this.lsystem === null) return null;
      const { regeln } = this.lsystem;
      const hashtabelle = {};
      for (let i = 0; i < regeln.length; i += 1) {
        const regel = regeln[i];
        hashtabelle[regel.vorgaenger] = regel.nachfolger;
      }
      return hashtabelle;
    },
    regelRegExp() {
      return new RegExp(Object.keys(this.regelHashtabelle)
        .join('|')
        .replace('+', '\\+'), 'gi');
    },
  },
  watch: {
    '$store.state.lsystem.name': {
      handler() {
        this.schritt = 0;
      },
    },
    '$store.state.lsystem.startwort': {
      handler() {
        this.ableitungenNeubestimmen();
      },
    },
    '$store.state.lsystem.regeln': {
      deep: true,
      handler() {
        this.ableitungenNeubestimmen();
      },
    },
    '$store.state.ableitungen': {
      deep: true,
      handler() {
        this.ableitungen = this.$store.getters.ableitungen;
      },
    },
    '$store.state.hervorhebungen': {
      deep: true,
      handler() {
        const { hervorhebungen } = this.$store.getters;
        const vorgaengerArray = [];

        if (hervorhebungen.length > 0) {
        // Bestimme Nachfolgerhervorhebungen in bereits vorhandenen Ableitungen
          while (hervorhebungen.length <= this.ableitungen.length) {
            if (hervorhebungen[hervorhebungen.length - 1] !== (undefined || null)) {
              this.bestimmeNachfolgerhervorhebungen(hervorhebungen.length - 1);
            }
          }

          let i = this.letzterUndefinierterIndex(hervorhebungen);
          let index = hervorhebungen[i + 1];

          // Bestimme sukzessive die Vorgänger vom Ableitungsschritt mit initialer Hervorhebung aus
          for (i; i >= 0; i -= 1) {
            const vorgaenger = this.bestimmeVorgaenger(
              this.ableitungen[i],
              index,
            );
            vorgaengerArray[i] = vorgaenger;
            index = vorgaenger;
          }
        }
        this.$store.commit('aendereVorgaenger', vorgaengerArray);
      },
    },
  },
  methods: {
    bestimmeNaechsteAbleitung() {
      const wort = this.ableitungen[this.ableitungen.length - 1];
      const naechstesWort = wort.replace(
        this.regelRegExp,
        (zeichen) => this.regelHashtabelle[zeichen] || '',
      );
      this.$store.commit('ableitungHinzufuegen', naechstesWort);
    },
    ableitungenNeubestimmen() {
      this.lsystem = this.$store.getters.lsystem;
      this.ableitungen = [this.lsystem.startwort];
      this.$store.commit('aendereAbleitungen', this.ableitungen);
      for (let i = 0; i < this.schritt; i += 1) {
        this.bestimmeNaechsteAbleitung();
      }
      this.$store.commit('aendereHervorhebungen', []);
    },
    schrittVor() {
      if (this.ableitungen.length - 1 <= this.schritt) {
        this.bestimmeNaechsteAbleitung();
      }
      this.bestimmeNachfolgerhervorhebungen(this.schritt);
      this.schritt += 1;
    },
    schrittVor_Wort() {
      if (!this.nurNaechsteZeichnung) {
        this.schrittVor();
        this.nurNaechstesWort = true;
      } else {
        this.nurNaechsteZeichnung = false;
      }
    },
    schrittVor_Zeichnung() {
      if (!this.nurNaechstesWort) {
        this.schrittVor();
        this.nurNaechsteZeichnung = true;
      } else {
        this.nurNaechstesWort = false;
      }
    },
    schrittZurueck() {
      this.schritt -= 1;
      this.nurNaechsteZeichnung = false;
      this.nurNaechstesWort = false;
    },
    bestimmeNachfolgerhervorhebungen(schritt) {
      const { hervorhebungen } = this.$store.getters;
      const naechsterSchritt = schritt + 1;

      if (hervorhebungen[schritt] !== undefined && hervorhebungen[naechsterSchritt] === undefined) {
        let nachfolgerhervorhebungen = [];
        for (let i = 0; i < hervorhebungen[schritt].length; i += 1) {
          nachfolgerhervorhebungen = nachfolgerhervorhebungen.concat(
            this.bestimmeNachfolger(
              this.ableitungen[schritt],
              hervorhebungen[schritt][i],
            ),
          );
        }
        hervorhebungen[naechsterSchritt] = nachfolgerhervorhebungen;
      }
    },
    bestimmeVorgaenger(wort, index) {
      const { regeln } = this.lsystem;
      let nachfolgerstelle = 0;
      for (let i = 0; i < wort.length; i += 1) {
        const z = wort[i];
        const regel = regeln.filter((r) => r.vorgaenger === z)[0];
        if (regel) {
          nachfolgerstelle += regel.nachfolger.length;
        } else {
          nachfolgerstelle += 1;
        }
        if (index < nachfolgerstelle) return i;
      } return undefined;
    },
    bestimmeNachfolger(wort, index) {
      const { regelHashtabelle } = this;
      let laengeNaechstesWort = 0;
      for (let i = 0; i < index; i += 1) {
        const z = wort[i];
        const nachfolger = regelHashtabelle[z];
        if (nachfolger) {
          laengeNaechstesWort += nachfolger.length;
        } else {
          laengeNaechstesWort += 1;
        }
      }
      const z = wort[index];
      const nachfolger = regelHashtabelle[z];
      if (nachfolger) {
        return Array.from(
          { length: nachfolger.length },
          (_, j) => j + laengeNaechstesWort,
        );
      }
      // Bei Identität wird das Zeichen nur im Wort verschoben
      return [laengeNaechstesWort];
    },
  },
};

</script>

<style scoped>

</style>
