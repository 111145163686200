<template>
  <v-card
    flat
  >
    <SvgPanZoom
      style="width: 100%; height: 600px; border: 2px ridge steelblue; border-radius: 5px"
      :zoom-enabled="true"
      :center="false"
      :fit="false"
      :contains="true"
      :min-zoom="0.01"
      :max-zoom="20"
      :zoom-scale-sensitivity="0.1"
      @svgpanzoom="registriereSvgPanZoom"
    >
      <svg id="visualisierung">
        <g id="g1">
          <defs>
            <marker
              id="arrow-linie"
              viewBox="0 0 30 30"
              refX="5"
              refY="5"
              markerUnits="strokeWidth"
              markerWidth="10"
              markerHeight="10"
              orient="auto"
            >
              <path
                d="M 0,0 l 10,5 l -10,5 z"
                fill="red"
              />
            </marker>
            <marker
              id="arrow-drehungMinus"
              viewBox="0 0 30 30"
              refX="9"
              refY="5"
              markerUnits="strokeWidth"
              markerWidth="10"
              markerHeight="10"
              orient="auto"
            >
              <path
                d="M 0,0 l 10,5 l -10,5 z"
                fill="red"
              />
            </marker>
            <marker
              id="arrow-drehungPlus"
              viewBox="0 0 30 30"
              refX="9"
              refY="5"
              markerUnits="strokeWidth"
              markerWidth="10"
              markerHeight="10"
              orient="auto-start-reverse"
            >
              <path
                d="M 0,0 l 10,5 l -10,5 z"
                fill="red"
              />
            </marker>
            <marker
              id="arrow-speicher"
              viewBox="0 0 30 30"
              refX="10"
              refY="5"
              markerUnits="strokeWidth"
              markerWidth="15"
              markerHeight="15"
              orient="auto"
            >
              <path
                d="M 0,0 l 10,5 l -10,5 z"
                fill="blue"
                stroke="white"
                stroke-width="1px"
              />
            </marker>

          </defs>
          <path
            :d="zeichenkoordinaten[0]"
            :class="{pfad : true, pfadGefuellt : zeichenoptionen.fuellen}"
          />

          <!--  Zeichnen der 'normalen' Linien  -->
          <line
            v-for="linie in zeichenkoordinaten[1]"
            :key="linie.zeichenindex"
            :x1="linie.start.x"
            :y1="linie.start.y"
            :x2="linie.ende.x"
            :y2="linie.ende.y"
            :class="{linie : true,
                     linieHervorgehoben : pruefeHervorhebungen(linie.zeichenindex),
                     linieHervorgehobenRichtungspfeil :
                       (pruefeHervorhebungen(linie.zeichenindex)
                         && anzeigehilfen.richtungspfeile),
                     hovered : isHovered}"
            @click="hervorheben(linie.zeichenindex)"
            @dblclick="hervorhebungAufheben"
            @mouseover="isHovered = true"
            @mouseleave="isHovered = false"
          />

          <!-- Zeichnen der Drehungen   -->
          <g
            v-for="drehung in zeichenkoordinaten[5]"
            :key="drehung.zeichenindex"
            v-show="pruefeHervorhebungen(drehung.zeichenindex) && anzeigehilfen.drehungen"
          >
            <path
              :d="drehung.kreisbogenpfad[0]"
              :class="{kreisbogenDrehungMinus :
                         drehung.typ.match('minus'),
                       kreisbogenDrehungPlus :
                         drehung.typ.match('plus')}"
            />
            <path
              :d="drehung.kreisbogenpfad[1]"
              :class="{ kreisbogenRandlinien : true}"
            />
          </g>

          <!-- Zeichnen der Vorgänger-Linien  -->
          <line
            v-if="enthaeltVorgaenger && anzeigehilfen.vorgaengerUndNachfolger"
            :x1="koordinatenVorgaenger.start.x"
            :y1="koordinatenVorgaenger.start.y"
            :x2="koordinatenVorgaenger.ende.x"
            :y2="koordinatenVorgaenger.ende.y"
            :class="{linieVorgaenger : true, hovered : isHovered}"
            @click="hervorheben(this.vorgaenger[this.schritt])"
            @mouseover="isHovered = true"
            @mouseleave="isHovered = false"
          />

          <!--  Zeichnen der Kreise, die das Speichern einer Position illustrieren -->
          <circle
            v-for="aktion in anzuzeigendePosSpeicheraktionen"
            :key="aktion.zeichenindex"
            :cx="aktion.position.x"
            :cy="aktion.position.y"
            r="2px"
            :class="{kreisPositionSpeichern : true, hovered : isHovered}"
            v-show="anzeigehilfen.positionsspeicherAktionen"
            @click="hervorheben(aktion.zeichenindex)"
            @mouseover="isHovered = true"
            @mouseleave="isHovered = false"
          />

          <!--  Zeichnen der Linien, die das Laden einer Position aus dem Speicher illustrieren-->
          <line
            v-for="aktion in anzuzeigendePosLadeaktionen"
            :key="aktion.zeichenindex"
            :x1="aktion.start.x"
            :y1="aktion.start.y"
            :x2="aktion.ende.x"
            :y2="aktion.ende.y"
            :class="{liniePositionLaden : true, hovered : isHovered}"
            v-show="anzeigehilfen.positionsspeicherAktionen"
            @click="hervorheben(aktion.zeichenindex)"
            @mouseover="isHovered = true"
            @mouseleave="isHovered = false"
          />

          <!--  Zeichnen der Linien, die ohne Strich 'gezeichnet' werden -->
          <line
            v-for="linie in anzuzeigendeLinienOhneStrich"
            :key="linie.zeichenindex"
            :x1="linie.start.x"
            :y1="linie.start.y"
            :x2="linie.ende.x"
            :y2="linie.ende.y"
            :class="{linieOhneStrich : true, hovered : isHovered}"
            @click="hervorheben(linie.zeichenindex)"
            @mouseover="isHovered = true"
            @mouseleave="isHovered = false"
          />
        </g>
      </svg>
    </SvgPanZoom>
    <a
      href="#"
      @click="downloadSVG($event)"
      class="mdi mdi-download"
    />
  </v-card>
</template>
<script>
import SvgPanZoom from 'vue-svg-pan-zoom';

export default {
  name: 'GeneratorZeichnung',
  components: { SvgPanZoom },
  props: {
    schritt: {
      type: Number,
      required: true,
      default: 0,
    },
    wort: {
      type: String,
      required: true,
      default: '',
    },
    zoomSlider: {
      type: Number,
      required: false,
      default: 500,
    },
  },
  data() {
    return {
      hervorhebungen: [[]],
      vorgaenger: [],
      svgpanzoom: null,
      isHovered: false,
      zeichenoptionen: {
        startwinkel: 0,
      },
      anzeigehilfen: {},
    };
  },
  computed: {
    enthaeltVorgaenger() {
      return this.vorgaenger[this.schritt] !== undefined
        && this.zeichenkoordinaten[1].filter(
          (s) => s.zeichenindex === this.vorgaenger[this.schritt],
        )[0] !== undefined;
    },
    koordinatenVorgaenger() {
      return this.zeichenkoordinaten[1].filter(
        (s) => s.zeichenindex === this.vorgaenger[this.schritt],
      )[0];
    },
    anzuzeigendeNachfolger() {
      if (this.hervorhebungen[this.schritt] !== undefined
        && this.hervorhebungen[this.schritt - 1] !== undefined
        && this.anzeigehilfen.vorgaengerUndNachfolger) {
        return this.hervorhebungen[this.schritt];
      }
      return [];
    },
    istInitialeHervorhebung() {
      if (this.hervorhebungen[this.schritt] !== undefined
        && this.hervorhebungen[this.schritt - 1] === undefined) {
        return this.hervorhebungen[this.schritt];
      }
      return [];
    },
    anzuzeigendePosLadeaktionen() {
      return this.zeichenkoordinaten[2].filter(
        (s) => this.pruefeHervorhebungen(s.zeichenindex),
      );
    },
    anzuzeigendePosSpeicheraktionen() {
      return this.zeichenkoordinaten[3].filter(
        (s) => this.pruefeHervorhebungen(s.zeichenindex),
      );
    },
    anzuzeigendeLinienOhneStrich() {
      return this.zeichenkoordinaten[4].filter(
        (s) => this.pruefeHervorhebungen(s.zeichenindex),
      );
    },
    zeichenkoordinaten() {
      const { wort } = this;
      if (wort !== undefined) {
        const { zeichenoptionen } = this.$store.getters;
        const winkelAenderung = this.$store.getters.lsystem.winkel;
        let winkel = this.zeichenoptionen.startwinkel;
        const startSchrittweite = 100;
        let schrittweite;
        if (!zeichenoptionen.konstanteStreckenlaenge) {
          schrittweite = startSchrittweite / (3 ** (this.schritt));
        } else {
          schrittweite = zeichenoptionen.streckenlaenge;
        }

        let position = { x: 100, y: 100 };
        const positionSpeicher = [];
        const pfad = [`M${position.x},${position.y}`];
        const linien = [];
        const linienOhneStrich = [];
        const positionSpeicheraktionen = [];
        const positionLadeaktionen = [];
        const drehungen = [];
        for (let i = 0; i < wort.length; i += 1) {
          const zeichen = wort.charAt(i);
          if (zeichen.match(/[A-M]/)) {
            const winkelInBogenmass = (winkel * Math.PI) / 180;
            const neuePosition = {
              x: position.x + schrittweite * Math.cos(winkelInBogenmass),
              y: position.y + schrittweite * Math.sin(winkelInBogenmass),
            };
            pfad.push([`L${neuePosition.x},${neuePosition.y}`]);
            linien.push({
              zeichenindex: i,
              start: position,
              ende: neuePosition,
            });
            position = neuePosition;
          } else if (zeichen.match(/[N-Z]/)) {
            const winkelInBogenmass = (winkel * Math.PI) / 180;
            const neuePosition = {
              x: position.x + schrittweite * Math.cos(winkelInBogenmass),
              y: position.y + schrittweite * Math.sin(winkelInBogenmass),
            };
            pfad.push([`M${neuePosition.x},${neuePosition.y}`]);
            linienOhneStrich.push({
              zeichenindex: i,
              start: position,
              ende: neuePosition,
            });
            position = neuePosition;
          } else if (zeichen === '+') {
            const neuerWinkel = winkel + winkelAenderung;
            drehungen.push({
              zeichenindex: i,
              typ: 'plus',
              kreisbogenpfad: this.beschreibeKreisbogen(position.x,
                position.y,
                schrittweite / 3,
                winkel,
                neuerWinkel),
            });
            winkel = neuerWinkel;
          } else if (zeichen === '-') {
            const neuerWinkel = winkel - winkelAenderung;
            drehungen.push({
              zeichenindex: i,
              typ: 'minus',
              kreisbogenpfad: this.beschreibeKreisbogen(position.x,
                position.y,
                schrittweite / 3,
                neuerWinkel,
                winkel),
            });
            winkel = neuerWinkel;
          } else if (zeichen === '[') {
            positionSpeicher.push([position, winkel]);
            positionSpeicheraktionen.push({ zeichenindex: i, position, winkel });
          } else if (zeichen === ']') {
            let neuePosition;
            [neuePosition, winkel] = positionSpeicher.pop();
            positionLadeaktionen.push({ zeichenindex: i, start: position, ende: neuePosition });
            pfad.push([`M${neuePosition.x},${neuePosition.y}`]);
            position = neuePosition;
          }
        }
        return [pfad.join(' '), linien,
          positionLadeaktionen, positionSpeicheraktionen,
          linienOhneStrich, drehungen];
      }
      return [];
    },
  },
  methods: {
    registriereSvgPanZoom(svgpanzoom) {
      this.svgpanzoom = svgpanzoom;
    },
    hervorheben(index) {
      const hervorhebungen = [];
      hervorhebungen[this.schritt] = [index];
      this.$store.commit('aendereHervorhebungen', hervorhebungen);
    },
    hervorhebungAufheben() {
      this.$store.commit('aendereHervorhebungen', []);
    },
    pruefeHervorhebungen(index) {
      return this.istInitialeHervorhebung.includes(index)
        || this.anzuzeigendeNachfolger.includes(index);
    },
    polarZuKartesisch(x, y, radius, winkelGrad) {
      const winkelBogenmass = winkelGrad * (Math.PI / 180.0);
      return {
        x: x + (radius * Math.cos(winkelBogenmass)),
        y: y + (radius * Math.sin(winkelBogenmass)),
      };
    },
    beschreibeKreisbogen(x, y, radius, startwinkel, endwinkel) {
      const start = this.polarZuKartesisch(x, y, radius, endwinkel);
      const ende = this.polarZuKartesisch(x, y, radius, startwinkel);
      const startLinie = this.polarZuKartesisch(x, y, radius * 1.3, endwinkel);
      const endeLinie = this.polarZuKartesisch(x, y, radius * 1.3, startwinkel);
      const optionGrosserBogen = endwinkel - startwinkel <= 180 ? '0' : '1';
      const kreisbogen = [
        'M', start.x, start.y,
        'A', radius, radius, 0, optionGrosserBogen, 0, ende.x, ende.y,
      ].join(' ');
      const randlinien = [
        'M', start.x, start.y,
        'L', x, y,
        'L', startLinie.x, startLinie.y,
        'M', x, y,
        'L', endeLinie.x, endeLinie.y,
      ].join(' ');
      return [kreisbogen, randlinien];
    },
    downloadSVG(evt) {
      const svgContent = document.getElementById('visualisierung').outerHTML;
      const blob = new Blob([svgContent], {
        type: 'image/svg+xml',
      });
      const url = window.URL.createObjectURL(blob);
      const link = evt.target;

      link.target = '_blank';
      link.download = `${this.$store.getters.lsystem.name}_Schritt-${this.schritt}`;
      link.href = url;
    },
  },
  watch: {
    '$store.state.hervorhebungen': {
      deep: true,
      handler() {
        this.hervorhebungen = this.$store.getters.hervorhebungen;
      },
    },
    '$store.state.zeichenoptionen': {
      deep: true,
      handler() {
        this.zeichenoptionen = this.$store.getters.zeichenoptionen;
      },
    },
    '$store.state.anzeigehilfen': {
      deep: true,
      handler() {
        this.anzeigehilfen = this.$store.getters.anzeigehilfen;
      },
    },
  },
  mounted() {
    this.hervorhebungen = this.$store.getters.hervorhebungen;
    this.vorgaenger = this.$store.getters.vorgaenger;
    this.zeichenoptionen = this.$store.getters.zeichenoptionen;
    this.anzeigehilfen = this.$store.getters.anzeigehilfen;
  },
  updated() {
    this.vorgaenger = this.$store.getters.vorgaenger;
  },
};
</script>

<style scoped>
  svg {
    width: 100%;
    height: 100%;
  }
  .hovered {
      cursor: pointer;
    }
  .pfad {
    stroke-width: 1px;
    stroke: black;
    fill: none;
  }
  .pfadGefuellt {
    fill: lightgray;
  }
  .linie {
    stroke: transparent;
    stroke-width: 1px;
    stroke-linecap: round
  }
  .linieVorgaenger {
    stroke: red;
    stroke-width: 1.5px;
    stroke-dasharray: 2,4;
    stroke-linecap: round;
  }
  .linieHervorgehoben {
    stroke: red;
  }
  .linieHervorgehobenRichtungspfeil {
    marker-end: url(#arrow-linie);
  }
  .liniePositionLaden {
    stroke: #0000ff;
    stroke-linecap: round;
    stroke-width: 0.5px;
    stroke-dasharray: 2,2;
    marker-end: url(#arrow-speicher);
    opacity: 0.8;
  }
  .kreisPositionSpeichern {
    r: 2px;
    fill: blue;
    opacity: 0.8;
  }
  .linieOhneStrich {
    stroke: red;
    stroke-width: 1px;
    stroke-dasharray: 2,4;
    stroke-linecap: butt;
  }
  .kreisbogenDrehungMinus {
    stroke: red;
    fill: none;
    stroke-width: 0.5px;
    stroke-dasharray: 1,1;
    stroke-linecap: butt;
    marker-end: url(#arrow-drehungMinus);
  }
  .kreisbogenDrehungPlus {
    stroke: red;
    fill: none;
    stroke-width: 0.5px;
    stroke-dasharray: 1,1;
    stroke-linecap: butt;
    marker-start: url(#arrow-drehungPlus);
  }
  .kreisbogenRandlinien {
    stroke: red;
    fill: none;
    stroke-width: 0.5px;
    stroke-dasharray: 1,1;
    stroke-linejoin: round;
  }
</style>
