<template>
  <v-container>
    <v-row no-gutters>
      <v-col
        md="4"
        offset="4"
      >
        <v-btn
          v-if="!nurNaechsteZeichnung"
          color="primary"
          outlined
          x-small
          @click="$emit('schrittVorNurZeichnung')"
        >
          <v-icon
            small
            color="primary"
          >
            mdi-step-forward
          </v-icon>
          Nächste Zeichnung
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        cols="1"
        align-self="center"
      >
        <v-btn
          v-if="schritt>0"
          color="primary"
          outlined
          small
          @click="$emit('schrittZurueck')"
        >
          <v-icon
            large
            color="primary"
          >
            mdi-step-backward
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        v-if="ableitungsschrittAnzeigen"
        cols="2"
      >
        <h2 align="center">
          {{ schritt }}.
        </h2>
        <h4 align="center">
          Ableitung
        </h4>
      </v-col>
      <v-col
        v-else-if="startwortAnzeigen"
        cols="2"
      >
        <h4 align="center">
          Startwort
        </h4>
      </v-col>
      <v-col
        cols="1"
        align-self="center"
      >
        <v-btn
          v-if="!nurWortOderZeichnung"
          color="primary"
          outlined
          small
          @click="$emit('schrittVor')"
        >
          <v-icon
            large
            color="primary"
          >
            mdi-step-forward
          </v-icon>
        </v-btn>
      </v-col>
      <!--      <v-col cols="4" />-->
    </v-row>
    <v-row no-gutters>
      <v-col
        md="4"
        offset="4"
      >
        <v-btn
          v-if="!nurNaechstesWort"
          color="primary"
          outlined
          x-small
          @click="$emit('schrittVorNurWort')"
        >
          <v-icon
            small
            color="primary"
          >
            mdi-step-forward
          </v-icon>
          Nächstes Wort
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'GeneratorSteuerung',
  components: {},
  props: {
    schritt: {
      type: Number,
      required: true,
      default: 0,
    },
    ableitungen: {
      type: Array,
      required: true,
      default() { return []; },
    },
    zeigeSchritt: {
      type: Boolean,
      required: false,
      default: false,
    },
    nurNaechstesWort: {
      type: Boolean,
      required: false,
      default: false,
    },
    nurNaechsteZeichnung: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    nurWortOderZeichnung() {
      return this.nurNaechstesWort || this.nurNaechsteZeichnung;
    },
    startwortAnzeigen() {
      return (this.schritt === 0 && this.zeigeSchritt);
    },
    ableitungsschrittAnzeigen() {
      return (this.schritt > 0 && this.zeigeSchritt);
    },
  },
};
</script>

<style scoped>

</style>
