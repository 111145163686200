<template>
  <v-container>
    <v-row>
      <v-col
        align-self="center"
        offset="4"
      >
        <v-btn
          v-if="schritt>0"
          color="primary"
          outlined
          small
          @click="$emit('schrittZurueck')"
        >
          <v-icon
            large
            color="primary"
          >
            mdi-step-backward mdi-rotate-90
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="!nurNaechstesWort"
          color="primary"
          outlined
          x-small
          @click="$emit('schrittVorNurWort')"
        >
          <v-icon
            small
            color="primary"
          >
            mdi-step-forward mdi-rotate-90
          </v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          v-if="!nurWortOderZeichnung"
          color="primary"
          outlined
          small
          @click="$emit('schrittVor')"
        >
          <v-icon
            large
            color="primary"
          >
            mdi-step-forward mdi-rotate-90
          </v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          v-if="!nurNaechsteZeichnung"
          color="primary"
          outlined
          x-small
          @click="$emit('schrittVorNurZeichnung')"
        >
          <v-icon
            small
            color="primary"
          >
            mdi-step-forward mdi-rotate-90
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'GeneratorSteuerungVertikal',
  components: {},
  props: {
    schritt: {
      type: Number,
      required: true,
      default: 0,
    },
    ableitungen: {
      type: Array,
      required: true,
      default() { return []; },
    },
    zeigeSchritt: {
      type: Boolean,
      required: false,
      default: false,
    },
    nurNaechstesWort: {
      type: Boolean,
      required: false,
      default: false,
    },
    nurNaechsteZeichnung: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    nurWortOderZeichnung() {
      return this.nurNaechstesWort || this.nurNaechsteZeichnung;
    },
  },
};
</script>

<style scoped>

</style>
