<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      clipped-left
    >
      <v-app-bar-title>
        <h2
          style="color: white;
                -webkit-text-stroke: 1px black;"
        >
          ELVis
        </h2>
        <h5>
          <span
            style="color: white;
                  -webkit-text-stroke: 0.5px black;"
          >E</span><span>ducational </span>
          <span
            style="color: white;
                  -webkit-text-stroke: 0.5px black;"
          >L</span><span>-System </span>
          <span
            style="color: white;
                  -webkit-text-stroke: 0.5px black;"
          >Vis</span><span>ualizer</span>
        </h5>
      </v-app-bar-title>
    </v-app-bar>

    <v-navigation-drawer
      clipped
      app
      permanent
      class="mx-2"
    >
      <LSystemEingabe />
    </v-navigation-drawer>

    <v-main>
      <v-container>
        <Generator />
      </v-container>
    </v-main>

    <v-footer
      color="primary"
      padless
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-col
          class="primary py-1 text-center white--text"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>ELVis</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import LSystemEingabe from '@/components/LSystemEingabe.vue';
import Generator from '@/components/Generator.vue';

export default {
  name: 'App',

  components: {
    Generator,
    LSystemEingabe,
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped lang="scss">
v-app-bar {
  v-app-bar-title {
    h2 {
      color: white;
      -webkit-text-stroke: 1px black;
    }

    h5 {
      .h {
        color: white;
        -webkit-text-stroke: 0.5px black;
      }
    }
  }
}
</style>
