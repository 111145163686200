<template>
  <div>
    <v-select
      :items="beispiele"
      :key="beispiele.index"
      item-text="name"
      item-value="index"
      v-model="gewaehltesBeispiel"
      label="Beispiel-L-Systeme"
      outlined
      style="margin: 10px"
      @change="uebernehmeBeispiel"
    />
    <v-divider />
    <v-toolbar
      flat
      dense
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            color="indigo"
            class="ma-sm-0"
            x-small
            dark
            @click="speichernDialog = true"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              small
              dark
            >
              mdi-cloud-upload
            </v-icon>
          </v-btn>
        </template>
        <span>Speichern</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            color="indigo"
            class="ma-sm-1"
            x-small
            dark
            @click="ladenDialog = true"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              small
              dark
            >
              mdi-cloud-download
            </v-icon>
          </v-btn>
        </template>
        <span>Laden</span>
      </v-tooltip>
      <v-menu offset-y>
        <template #activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template #activator="{ on: tooltip }">
              <v-btn
                color="primary"
                class="ma-md-1"
                x-small
                dark
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon
                  dark
                  small
                >
                  mdi-download
                </v-icon>
              </v-btn>
            </template>
            <span>In Datei exportieren</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item @click="exportieren('system')">
            <v-list-item-title>Aktuelles L-System</v-list-item-title>
          </v-list-item>
          <v-list-item @click="exportieren('speicher')">
            <v-list-item-title>Alle gespeicherten L-Systeme</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            class="ma-sm-0"
            x-small
            dark
            v-bind="attrs"
            v-on="on"
            @click="importierenDialog = true"
          >
            <v-icon
              dark
              small
            >
              mdi-upload
            </v-icon>
          </v-btn>
        </template>
        <span>Aus Datei importieren</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            color="red"
            class="ma-2"
            x-small
            dark
            v-bind="attrs"
            v-on="on"
            @click="loeschen()"
          >
            <v-icon
              dark
              small
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
        <span>Löschen</span>
      </v-tooltip>
    </v-toolbar>
    <!-- Dialog zum Speichern eines L-Systems   -->
    <v-dialog
      v-model="speichernDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          L-System speichern
        </v-card-title>
        <v-card-text v-if="gueltig">
          <v-text-field
            class="align-center"
            v-model="lsystem.name"
            placeholder="Name des L-Systems"
            label="Name"
            @keypress.enter="systemSpeichern()"
          />
          <v-btn
            color="primary"
            dark
            @click="systemSpeichern()"
          >
            Speichern
          </v-btn>
        </v-card-text>
        <v-card-text v-else>
          Kein gültiges System!
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="speichernDialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog zum Laden eines L-Systems   -->
    <v-dialog
      v-model="ladenDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          L-System laden
        </v-card-title>
        <v-card-text v-if="this.$store.getters.gespeicherteSysteme.length > 0">
          <v-select
            :items="this.$store.getters.gespeicherteSysteme"
            v-model="ladendesSystem"
            item-text="speicherzeit"
            item-value="index"
            label="Gespeicherte L-Systeme"
            outlined
            style="margin: 10px"
            return-object
          >
            <template
              slot="selection"
              slot-scope="{ item }"
            >
              {{ item.name }}
              (gespeichert am {{ item.speicherdatum }} um {{ item.speicherzeit }})
            </template>
            <template
              slot="item"
              slot-scope="{ item }"
            >
              {{ item.name }}
              (gespeichert am {{ item.speicherdatum }} um {{ item.speicherzeit }})
            </template>
          </v-select>
          <v-btn
            color="primary"
            dark
            @click="systemLaden()"
          >
            Laden
          </v-btn>
        </v-card-text>
        <v-card-text v-else>
          Es sind keine gespeicherten L-Systeme vorhanden.
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="ladenDialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog zum Importieren eines oder mehrerer L-Systeme   -->
    <v-dialog
      v-model="importierenDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Aus Datei importieren
        </v-card-title>
        <v-card-text>
          <v-file-input
            v-model="importierendeDatei"
            accept="text/plain"
            placeholder="Lade hier deine gespeicherten L-Systeme hoch"
            prepend-icon="mdi-paperclip"
          >
            <template #selection="{ text }">
              <v-chip
                small
                label
                color="primary"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-btn
            color="primary"
            dark
            @click="importieren()"
          >
            Hochladen
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="importierenDialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---->
    <v-divider />
    <!---->
    <v-form
      ref="eingabe"
      v-model="gueltig"
    >
      <v-card
        flat
        color="transparent"
      >
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                class="align-center"
                v-model="lsystem.name"
                placeholder="Name des L-Systems"
                label="Name"
              />
            </v-col>
          </v-row>
          <!-- <h3 style="margin: 20px;text-align: center">
            Grundlegende Einstellungen
          </h3> -->
          <v-row>
            <v-col>
              <v-text-field
                class="align-center"
                v-model="lsystem.startwort"
                :rules="startwortValidierungsregeln"
                placeholder="Startwort"
                label="Startwort"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pr-4">
              <v-slider
                v-model="lsystem.winkel"
                class="align-center"
                :max="360"
                :min="0"
                hide-details
                thumb-label
                label="Winkel"
              >
                <template #append>
                  <v-text-field
                    v-model="lsystem.winkel"
                    class="mt-0 pt-0"
                    :max="360"
                    :min="0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 50px"
                  />
                </template>
              </v-slider>
            </v-col>
          </v-row>

          <v-divider style="margin: 20px" />
          <h3 style="margin: 20px;text-align: center">
            Regeln
          </h3>
          <v-row
            v-for="(regel, index) in lsystem.regeln"
            :key="index"
            class="align-center"
            no-gutters
          >
            <v-col cols="2">
              <v-text-field
                v-model="regel.vorgaenger"
                :label="++index+'.'"
                :rules="regelVorgaengerValidierungsregeln"
              />
            </v-col>
            <v-col cols="1">
              &rarr;
            </v-col>
            <v-col cols="8">
              <v-textarea
                v-model="regel.nachfolger"
                label="Regel"
                auto-grow
                rows="1"
                :rules="regelNachfolgerValidierungsregeln"
              />
            </v-col>
            <v-col cols="1">
              <v-btn
                outlined
                icon
                x-small
                color="red"
                @click="lsystem.regeln.splice(index-1, 1)"
              >
                <v-icon
                  color="red"
                  small
                >
                  mdi-minus
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                class="d-flex justify-center mb-6"
                outlined
                icon
                x-small
                color="primary"
                @click="lsystem.regeln.push({vorgaenger: '', nachfolger: ''})"
              >
                <v-icon
                  color="primary"
                  small
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider style="margin: 20px" />
          <v-divider />
          <h3 style="margin: 20px;text-align: center">
            Zeichenoptionen
          </h3>
          <v-row>
            <v-col class="pr-4">
              <v-slider
                v-model="zeichenoptionen.startwinkel"
                class="align-center"
                :max="360"
                :min="0"
                hide-details
                label="Startwinkel"
              >
                <template #append>
                  <v-text-field
                    v-model="zeichenoptionen.startwinkel"
                    class="mt-0 pt-0"
                    :max="360"
                    :min="0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 45px"
                  />
                </template>
              </v-slider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                v-model="zeichenoptionen.konstanteStreckenlaenge"
                label="Konstante Streckenlängen"
              >
                <template #append>
                  <v-text-field
                    v-model="zeichenoptionen.streckenlaenge"
                    class="mt-0 pt-0"
                    :max="50"
                    :min="1"
                    hide-details
                    single-line
                    type="number"
                    style="width: 40px"
                    :disabled="!zeichenoptionen.konstanteStreckenlaenge"
                  />
                </template>
              </v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                v-model="zeichenoptionen.fuellen"
                label="Flächen füllen"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-expansion-panels class="mx-0">
              <v-expansion-panel>
                <v-expansion-panel-header
                  disable-icon-rotate
                >
                  <h4>
                    Anzeigen und Hilfen
                  </h4>
                  <template #actions>
                    <v-icon color="information">
                      mdi-help-circle
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-checkbox
                    v-model="anzeigehilfen.richtungspfeile"
                    label="Richtungspfeile"
                    color="red"
                    hide-details
                  />
                  <v-checkbox
                    v-model="anzeigehilfen.drehungen"
                    label="Drehungen"
                    color="red"
                    hide-details
                  />
                  <v-checkbox
                    v-model="anzeigehilfen.vorgaengerUndNachfolger"
                    label="Vorgänger und Nachfolger"
                    color="red"
                    hide-details
                  />
                  <v-checkbox
                    v-model="anzeigehilfen.positionsspeicherAktionen"
                    label="Aktionen des Positionsspeichers"
                    color="blue"
                    hide-details
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import download from 'downloadjs';

export default {
  name: 'LSystemEingabe',
  data() {
    return {
      gueltig: false,
      startwortValidierungsregeln: [
        (w) => !!w || 'Es muss ein Startwort geben!',
        (w) => this.klammernAusgeglichen(w) || 'Ungleich viele ->[<- wie ->]<- !',
      ],
      regelVorgaengerValidierungsregeln: [
        (w) => !!w || 'Fehlt',
        (w) => !(this.lsystem.regeln.filter((s) => (w === s.vorgaenger)).length > 1) || 'Vorhanden!',
      ],
      regelNachfolgerValidierungsregeln: [
        (w) => !!w || 'Es muss einen Nachfolger geben!',
        (w) => this.klammernAusgeglichen(w) || 'Ungleich viele ->[<- wie ->]<- !',
      ],

      beispiele: [],
      gewaehltesBeispiel: '',

      lsystem: {
        name: '',
        startwort: '',
      },

      zeichenoptionen: {},
      anzeigehilfen: {},

      speichernDialog: false,
      ueberschreibenDialog: false,
      ladenDialog: false,
      ladendesSystem: {},
      importierenDialog: false,
      importierendeDatei: null,
    };
  },
  methods: {
    klammernAusgeglichen(wort) {
      const klAuf = new RegExp('\\[', 'g');
      const klZu = new RegExp('\\]', 'g');
      if (wort.match(klAuf) === null && wort.match(klZu) === null) return true;
      if (wort.match(klAuf) === null && wort.match(klZu) !== null) return false;
      if (wort.match(klAuf) !== null && wort.match(klZu) === null) return false;
      if (wort.match(klAuf).length !== wort.match(klZu).length) return false;
      return true;
    },
    uebernehmeBeispiel() {
      const lsystemBeispiel = {
        ...this.beispiele.find(
          (b) => b.name === this.gewaehltesBeispiel,
        ),
      };
      this.lsystem = this.lsystemKopie(lsystemBeispiel);
      this.uebernehmeLsystem();
    },
    uebernehmeLsystem() {
      this.$refs.eingabe.validate();
      this.$store.commit('aendereLsystem', this.lsystem);
    },
    systemSpeichern() {
      this.$store.commit('speichereSystem', this.lsystem);
      this.ueberschreibenDialog = false;
      this.speichernDialog = false;
    },
    systemLaden() {
      this.lsystem = this.lsystemKopie(this.ladendesSystem);
      this.uebernehmeLsystem();
      this.ladenDialog = false;
    },
    loeschen() {
      this.$store.commit('loeschen');
      this.lsystem = this.$store.getters.lsystem;
      this.gewaehltesBeispiel = '';
    },
    exportieren(type) {
      if (type === 'system') {
        download(JSON.stringify(this.lsystem),
          `${this.lsystem.name}.txt`, 'text/plain');
      } else if (type === 'speicher') {
        download(JSON.stringify(this.$store.getters.gespeicherteSysteme),
          `${this.$store.getters.gespeicherteSysteme.length}_Systeme--
          ${new Date().toLocaleString('de-DE')}`, 'text/plain');
      }
    },
    importieren() {
      if (!this.importierendeDatei) return 'Ungueltig';
      const reader = new FileReader();
      reader.onload = (e) => this.importierenReader(e);
      reader.readAsText(this.importierendeDatei);
      return 'Fehler beim Importieren!';
    },
    importierenReader(e) {
      const text = e.target.result;
      const systeme = JSON.parse(text);
      if (systeme.length > 1) {
        this.$store.commit('importiereSysteme', systeme);
        this.importierenDialog = false;
        return 'L-Systeme wurden dem Speicher hinzugefügt!';
      }
      this.lsystem = systeme;
      this.uebernehmeLsystem();
      this.importierenDialog = false;
      return 'L-System wurde importiert';
    },
  },
  mounted() {
    this.beispiele = this.$store.getters.beispiele;
    this.lsystem = this.$store.getters.lsystem;
    this.zeichenoptionen = this.$store.getters.zeichenoptionen;
    this.anzeigehilfen = this.$store.getters.anzeigehilfen;
  },
};
</script>

<style scoped lang="scss">

</style>
