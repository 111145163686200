<template>
  <v-container>
    <v-card-text>
      <v-row no-gutters>
        <v-col
          cols="1"
          align-self="center"
        >
          <v-btn
            fab
            color="red"
            icon
            x-small
            v-if="hervorhebungLinksverschiebbar"
            @click="hervorheben(hervorhebungen[schritt]-1)"
          >
            <v-icon
              large
            >
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-col>
        <v-col align-self="center">
          <span
            v-for="(z, index) in wort"
            :key="index"
            v-text="z"
            @click="hervorheben(index)"
            @dblclick="hervorhebungAufheben"
            @mouseover="isHovered = true"
            @mouseleave="isHovered = false"
            :class="{hovered : isHovered}"
            :style="{color: [istHervorgehoben[index] ? hervorhebungFarbe[index] : 'black'],
                     border: [anzuzeigendeVorgaenger[index] ? '0.5px solid red' : 'none'],
                     fontWeight: [istInitialeHervorhebung[index] ? 'bold' : 'normal'],
            }"
          />
        </v-col>
        <v-col
          cols="1"
          align-self="center"
        >
          <v-btn
            v-if="hervorhebungRechtsverschiebbar"
            color="red"
            fab
            icon
            x-small
            @click="hervorheben(parseInt(hervorhebungen[schritt]) + 1)"
          >
            <v-icon
              large
            >
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>
<script>
export default {
  name: 'GeneratorWort',
  data() {
    return {
      hervorhebungen: [[]],
      vorgaenger: [],
      anzeigehilfen: {},
      isHovered: false,
    };
  },
  props: {
    schritt: {
      type: Number,
      required: true,
      default: 0,
    },
    wort: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    istInitialeHervorhebung() {
      const { hervorhebungen } = this;
      const { schritt } = this;
      const { wort } = this;
      if (hervorhebungen[schritt] !== undefined
        && hervorhebungen[schritt - 1] === undefined) {
        return wort.split('').map(
          (z, index) => (hervorhebungen[schritt].includes(index)),
        );
      }
      return false;
    },
    istHervorgehoben() {
      const { hervorhebungen } = this;
      const { schritt } = this;
      const { wort } = this;
      const { anzeigehilfen } = this;
      if (hervorhebungen[schritt] === undefined) return false;
      if (anzeigehilfen.vorgaengerUndNachfolger) {
        return wort.split('')
          .map(
            (z, index) => hervorhebungen[schritt].includes(index),
          );
      }
      return this.istInitialeHervorhebung;
    },
    hervorhebungLinksverschiebbar() {
      const { hervorhebungen } = this;
      const { schritt } = this;
      return hervorhebungen[schritt] !== undefined
              && hervorhebungen[schritt].length === 1
              && !(hervorhebungen[schritt].includes(0));
    },
    hervorhebungRechtsverschiebbar() {
      const { hervorhebungen } = this;
      const { schritt } = this;
      const { wort } = this;
      return hervorhebungen[schritt] !== undefined
        && hervorhebungen[schritt].length === 1
        && !hervorhebungen[schritt].includes(wort.length - 1);
    },
    hervorhebungFarbe() {
      const { wort } = this;
      return wort.split('').map(
        (z) => {
          if (z === '[' || z === ']') return 'blue';
          return 'red';
        },
      );
    },
    istVorgaenger() {
      const { vorgaenger } = this;
      const { schritt } = this;
      const { wort } = this;
      if (vorgaenger[schritt] === undefined) return false;
      return wort.split('').map(
        (z, index) => (vorgaenger[schritt] === index),
      );
    },
    anzuzeigendeVorgaenger() {
      const { anzeigehilfen } = this;
      if (anzeigehilfen.vorgaengerUndNachfolger) return this.istVorgaenger;
      return [];
    },
  },
  methods: {
    hervorheben(index) {
      const hervorhebungen = [];
      hervorhebungen[this.schritt] = [index];
      this.$store.commit('aendereHervorhebungen', hervorhebungen);
    },
    hervorhebungAufheben() {
      this.$store.commit('aendereHervorhebungen', []);
    },
  },
  watch: {
    'this.$store.ableitungen': {
      handler() {
        this.ableitungen = this.$store.getters.ableitungen;
      },
    },
    '$store.state.hervorhebungen': {
      deep: true,
      handler() {
        this.hervorhebungen = this.$store.getters.hervorhebungen;
      },
    },
    '$store.state.vorgaenger': {
      deep: true,
      handler() {
        this.vorgaenger = this.$store.getters.vorgaenger;
      },
    },
  },
  mounted() {
    this.hervorhebungen = this.$store.getters.hervorhebungen;
    this.vorgaenger = this.$store.getters.vorgaenger;
    this.anzeigehilfen = this.$store.getters.anzeigehilfen;
  },
  beforeUpdate() {
    this.vorgaenger = this.$store.getters.vorgaenger;
  },
};
</script>

<style scoped>
span {
  letter-spacing: 2px;
  line-height: 1.2;
  font-size: 20px;
}
.hovered {
    cursor: pointer;
  }

</style>
